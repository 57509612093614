var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Provider } from 'react-redux';
import store from '@local/src/Store/configureStore';
import { AppShellDataProvider } from '@local/src/Utils/AppShellData';
import { AppShellRouter, KeyRoute } from '@local/src/Utils/Router';
import { SearchResults } from '@local/src/Components/SearchResults';
import { SearchField } from '@local/src/Components/SearchField';
import { SearchSuggestions } from '@local/src/Components/SearchSuggestions';
import { useQuery } from '@local/src/Utils/Hooks';
import { SearchContextProvider } from '@local/src/Utils/Context';
import { ThemeProvider, createTheme } from '@mui/material';
import { defaultLocale, themeOptions } from '@trr/mui-theme';
export var Pages;
(function (Pages) {
    Pages["Sok"] = "sok";
})(Pages || (Pages = {}));
var App = function (appShellData) {
    var searchQuery = useQuery().searchQuery;
    var theme = createTheme(themeOptions, defaultLocale);
    var shouldShowSearchSuggestions = searchQuery.query.length === 0;
    var shouldShowSearchResults = searchQuery.query.length !== 0;
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(AppShellDataProvider, __assign({ value: appShellData }, { children: _jsx(AppShellRouter, __assign({ currentKey: appShellData.currentKey }, { children: _jsx(KeyRoute, __assign({ urlKey: Pages.Sok }, { children: _jsx(SearchContextProvider, { children: _jsxs("section", { children: [_jsx(SearchField, {}), shouldShowSearchSuggestions && _jsx(SearchSuggestions, {}), shouldShowSearchResults && (_jsx(SearchResults, { noSearchResultContent: appShellData.content }))] }) }) })) })) })) })) })));
};
export default App;
