var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs, pageName = _a.pageName;
    return (_jsxs(MuiBreadcrumbs, __assign({ separator: _jsx(NavigateNext, { fontSize: "small" }), "aria-label": "breadcrumb", sx: { mt: 2 }, component: "div" }, { children: [breadcrumbs.slice(1).map(function (_a) {
                var name = _a.name;
                return (_jsx(Typography, __assign({ variant: "subtitle1", component: "span" }, { children: name }), name));
            }), _jsx(Typography, __assign({ variant: "subtitle1", component: "span" }, { children: pageName }))] })));
};
export default Breadcrumbs;
