import getConfig from '@local/Utils/ConfigService/getConfig';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/';
import { getOIDCUser } from '@trr/app-shell-communication';
export var PUBLIC_WEB_API_URL = getConfig().PUBLIC_WEB_API_URL;
var user = getOIDCUser();
export var trrFetchBaseQuery = function () {
    return fetchBaseQuery({
        baseUrl: "".concat(PUBLIC_WEB_API_URL),
        prepareHeaders: function (headers) {
            headers.set('Accept-Language', 'sv');
            (user === null || user === void 0 ? void 0 : user.id_token) && headers.set('authorization', "Bearer ".concat(user.id_token));
            return headers;
        },
    });
};
